<div class="breadcrumb_block" *ngIf="globals.institute_details">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <!-- <ol class="breadcrumb">
                    <li class="breadcrumb-item" [ngClass]="{'active':globals.breadcrumbs[1]==''}">
                        {{globals.breadcrumbs[0]}}
                    </li>
                    <li class="breadcrumb-item" *ngIf="globals.breadcrumbs[1]!=''" [ngClass]="{'active':globals.breadcrumbs[2]==''}">
                        {{globals.breadcrumbs[1]}}
                    </li>
                    <li class="breadcrumb-item" *ngIf="globals.breadcrumbs[2]!=''" [ngClass]="{'active':globals.breadcrumbs[3]!=''}">
                        {{globals.breadcrumbs[2]}}
                    </li>
                </ol> -->
                <div class="admin_institute_block" *ngIf="globals.institute_details">
                    <div class="current_view_status" [ngClass]="{'arrow': globals.authData.list.length>1}">
                        <span>
                            <ng-container *ngIf="globals.institute_details.role_value==1">OESS Administrator
                            </ng-container>
                            <ng-container *ngIf="globals.institute_details.role_value==2">IFMA Administrator
                            </ng-container>
                            <ng-container *ngIf="globals.institute_details.role_value==5">Individual view</ng-container>
                            <ng-container
                                *ngIf="globals.institute_details.role_value!=1 && globals.institute_details.role_value!=2 && globals.institute_details.role_value!=5">
                                Organization view
                            </ng-container>
                        </span>
                    </div>
                    <ng-container
                        *ngIf="globals.institute_details.institute!=null && globals.institute_details.institute!=''">
                        <div class="current_org me-3" [ngClass]="{'noborder_org': !(globals.authData.list.length>1)}">
                            <b>Selected Organization:</b><span *ngIf="globals.institute_details.institute.provider_id"
                                class="id_box mb-0 me-2">{{globals.institute_details.institute?.provider_id}}</span>
                            <span class="org_name">{{globals.institute_details?.institute?.institute_name}} </span>
                        </div>
                    </ng-container>
                    <!-- <a class="switch_button" *ngIf="globals.authData.list.length>1" (click)="select_view()"><i
                            class="fa fa-check-square-o" aria-hidden="true"></i> Select View</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="clearfix"></div>
<div class="switch_view_block">
    <h5 class="float-start">Select View</h5>
    <button class="float-end close" (click)="select_view_close()" type="button"><span>×</span></button>
    <div class="clearfix"></div>
    <p class="padding_5"><b>Current View:</b>
        <ng-container *ngIf="globals.institute_details.role_value==1"><span class="capital_text">OESS
                Administrator</span></ng-container>
        <ng-container *ngIf="globals.institute_details.role_value==2"><span class="capital_text">IFMA
                Administrator</span></ng-container>
        <ng-container *ngIf="globals.institute_details.role_value==5"><span class="capital_text">Individual User</span>
        </ng-container>
        <ng-container
            *ngIf="globals.institute_details.role_value!=1 && globals.institute_details.role_value!=2 && globals.institute_details.role_value!=5">
            <span class="capital_text">ORGANIZATIONS</span>
        </ng-container>
    </p>
    <ng-container *ngIf="OESS_Admin_present && globals.institute_details.role_value!=1">
        <p class="padding_15">
            <button class="view_btn" (click)="switchRole(1)">View as an OESS Administrator</button>
        </p>
    </ng-container>
    <ng-container *ngIf="AMSN_Admin_present && globals.institute_details.role_value!=2">
        <p class="padding_15">
            <button class="view_btn" (click)="switchRole(2)">View as an IFMA Administrator</button>
        </p>
    </ng-container>
    <ng-container *ngIf="Individual_present && globals.institute_details.role_value!=5">
        <p class="padding_15">
            <button class="view_btn" (click)="switchRole(5)">View as an Individual User</button>
        </p>
    </ng-container>

    <!-- <p >To select the different organization </p> -->
    <button class="all_btn theme_btn float-end" *ngIf="instituteList.length > 4"
        (click)="globals.dialogOpened = true;">Select Organization</button>

    <p class="mb-2"><b>Your Organizations</b></p>
    <div class="clearfix mb-2"></div>
    <div class="filter_block mb-3" *ngIf="Recently_instituteList.length > 0">
        <ul>
            <li><i class="fa fa-list-ul"></i>Type</li>
            <li><i class="fa fa-user"></i>Ownership</li>
        </ul>
    </div>
    <ng-container *ngFor="let institute of Recently_instituteList; let i=index;">
        <div class="border_line assessment_width">
            <div class="institute_block system_block pb-0">
                <div class="content_block">
                    <div class="id_box float-start me-3" *ngIf="institute?.provider_id!=null">
                        {{institute.provider_id}}</div>
                    <h5 class="float-start">{{institute?.institute_name}}</h5>
                    <div class="clearfix"></div>
                    <div class="address_phone">

                        <p class="system_name" *ngIf="institute?.institute_systems">
                            <b>System:</b>{{institute?.institute_systems.system}}
                        </p>
                        <p class="system_name" *ngIf="!institute?.institute_systems">
                            <b>System:</b>Not Affiliated
                        </p>

                        <p><i class="fa fa-map-marker"></i>
                            <span>{{institute.address1}}<br>{{institute.city}},
                                {{institute.state_abbreviation}} {{institute.zipcode}}
                            </span>
                        </p>
                        <p><i class="fa fa-phone"></i>
                            <span *ngIf="institute.phone_number">{{institute.phone_number |
                                phone}}</span>
                            <span *ngIf="!institute.phone_number">NA</span>
                        </p>

                        <p>
                            <i class="fa fa-list-ul"></i>
                            <span
                                *ngIf="institute.institute_types && institute.institute_types.display_text">{{institute.institute_types.display_text}}</span>
                            <span
                                *ngIf="!(institute.institute_types && institute.institute_types.display_text)">NA</span>
                        </p>
                        <p><i class="fa fa-user"></i>
                            <span
                                *ngIf="institute.institute_owners && institute.institute_owners.display_text">{{institute.institute_owners.display_text}}</span>
                            <span
                                *ngIf="!(institute.institute_owners && institute.institute_owners.display_text)">NA</span>
                        </p>
                    </div>
                    <div class="active_user_block">Divisions<br><b class="float-start">{{institute.total_divisions}}</b>
                    </div>
                    <div class="active_user_block">Units<br><b class="float-start">{{institute.total_units}}</b>
                    </div>
                    <div class="active_user_block mb-5">Users<br><b class="float-start">{{institute.total_users}}</b>
                    </div>
                    <ng-template
                        [ngIf]="globals.institute_details.institute!=null && globals.institute_details.institute!='' && institute.id==globals.institute_details.institute.id"
                        [ngIfElse]="noSelectedFound">
                        <div class="selected_btn"><span>Currently Selected</span></div>
                    </ng-template>
                    <ng-template #noSelectedFound>
                        <div class="selected_btn"><button class="all_btn theme_btn"
                                (click)="switchOrg(institute)">Select</button></div>
                    </ng-template>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- <div class="border_line assessment_width">
        <div class="institute_block system_block pb-0">
            <div class="content_block">
                <div class="id_box float-start me-3">234561</div>
                <h5 class="float-start">ABBEVILLE GENERAL HOSPITAL</h5>
                <div class="clearfix"></div>
                <div class="address_phone">
                   
                    <p class="system_name">
                        <b>System:</b>Kaiser Permanente Southern California
                    </p>
                    <p><i class="fa fa-map-marker"></i>
                        <span>address1<br>city, AF 123456
                        </span>
                    </p>
                    <p><i class="fa fa-phone"></i>
                        <span>1234567890</span>
                    </p>
                    <p>
                        <i class="fa fa-list-ul"></i>
                        <span>Critical Access Hospitals</span>
                    </p>
                    <p><i class="fa fa-user"></i>
                        <span>Government - Hospital District or Authority</span>
                    </p>
                </div>
                <div class="active_user_block">Divisions<br><b class="float-start">442</b></div>
                <div class="active_user_block">Units<br><b class="float-start">114</b>
                </div>
                <div class="active_user_block mb-5">Users<br><b class="float-start">453</b>
                </div>
                <div class="selected_btn"><span>Currently Selected</span></div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <div class="border_line assessment_width">
        <div class="institute_block system_block pb-0">
            <div class="content_block">
                <div class="id_box float-start me-3">234561</div>
                <h5 class="float-start">ABRAZO CENTRAL CAMPUS</h5>
                <div class="clearfix"></div>
                <div class="address_phone">
                   
                    <p class="system_name">
                        <b>System:</b>institute_systems
                    </p>
                    <p><i class="fa fa-map-marker"></i>
                        <span>address1<br>city, AF 123456
                        </span>
                    </p>
                    <p><i class="fa fa-phone"></i>
                        <span>1234567890</span>
                    </p>
                    <p>
                        <i class="fa fa-list-ul"></i>
                        <span>institute_types</span>
                    </p>
                    <p><i class="fa fa-user"></i>
                        <span>institute_owners</span>
                    </p>
                </div>
                <div class="active_user_block">Divisions<br><b class="float-start">442</b></div>
                <div class="active_user_block">Units<br><b class="float-start">114</b>
                </div>
                <div class="active_user_block mb-5">Users<br><b class="float-start">453</b>
                </div>
               
                <div class="selected_btn"><button class="all_btn theme_btn">Select</button></div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <div class="border_line assessment_width">
        <div class="institute_block system_block pb-0">
            <div class="content_block">
                <div class="id_box float-start me-3">234561</div>
                <h5 class="float-start">ABRAHAM LINCOLN MEMORIAL HOSPITAL</h5>
                <div class="clearfix"></div>
                <div class="address_phone">
                    <p class="system_name">
                        <b>System:</b>institute_systems
                    </p>
                    <p><i class="fa fa-map-marker"></i>
                        <span>address1<br>city, AF 123456
                        </span>
                    </p>
                    <p><i class="fa fa-phone"></i>
                        <span>1234567890</span>
                    </p>
                    <p>
                        <i class="fa fa-list-ul"></i>
                        <span>institute_types</span>
                    </p>
                    <p><i class="fa fa-user"></i>
                        <span>institute_owners</span>
                    </p>
                </div>
                <div class="active_user_block">Divisions<br><b class="float-start">442</b></div>
                <div class="active_user_block">Units<br><b class="float-start">114</b>
                </div>
                <div class="active_user_block mb-5">Users<br><b class="float-start">453</b>
                </div>
                
                <div class="selected_btn"><button class="all_btn theme_btn">Select</button></div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div> -->

</div>


<kendo-dialog [minWidth]="550" [width]="550" *ngIf="globals.dialogOpened" (close)="close();">
    <kendo-dialog-titlebar>
        <h5 *ngIf="globals.selected_institute==null" class="modal-title" id="switch_org_modalLabel">Select an
            Organization</h5>
        <h5 *ngIf="globals.selected_institute!=null" class="modal-title" id="switch_org_modalLabel">Switch an
            Organization</h5>
    </kendo-dialog-titlebar>
    <div kendoDialogContainer>

        <p class="padding_15">Below is the list of organizations that you are affiliated with. you can select/switch an
            organization from the list below.</p>

        <kendo-formfield [ngClass]="{'k-form-field-error': orgError}">
            <label><span>*</span> Organization</label>
            <kendo-dropdownlist id="institute_id" [(ngModel)]="institute_id" [data]="instituteList"
                [textField]="'institute_name'" [valueField]="'id'" [valuePrimitive]="true" [filterable]="true"
                (filterChange)="instituteFilter($event)" (selectionChange)="filterData($event)"
                [virtual]="globals.virtual">
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <span title="{{ dataItem?.institute_name }}">{{ dataItem?.institute_name
                        }}</span>
                </ng-template>
                <ng-template kendoMultiSelectNoDataTemplate>
                    <h6>NO ORGANIZATION FOUND</h6>
                </ng-template>
            </kendo-dropdownlist>
            <div class="k-form-error" *ngIf="orgError">
                Please select an organization.
            </div>
        </kendo-formfield>

        <p class="mb-2" *ngIf="selected_institute!=null"><b>Selected Organization</b></p>
        <div class="filter_block mt-2 mb-3" *ngIf="selected_institute!=null">
            <ul>
                <li><i class="fa fa-list-ul"></i>Type</li>
                <li><i class="fa fa-user"></i>Ownership</li>
            </ul>
        </div>
        <div class="border_line assessment_width" *ngIf="selected_institute!=null">
            <div class="institute_block pb-0">
                <div class="content_block">
                    <div class="address_phone">
                        <div class="id_box float-start me-3" *ngIf="selected_institute.provider_id!=null">
                            {{selected_institute.provider_id}}</div>
                        <h5 class="float-start">{{selected_institute?.institute_name}}</h5>
                        <div class="clearfix"></div>
                        <p class="system_name" *ngIf="selected_institute?.institute_systems">
                            <b>System:</b>{{selected_institute?.institute_systems.system}}
                        </p>
                        <p class="system_name" *ngIf="!selected_institute?.institute_systems">
                            <b>System:</b>Not Affiliated
                        </p>
                        <p><i class="fa fa-map-marker"></i>
                            <span>{{selected_institute.address1}}<br>{{selected_institute.city}},
                                <!-- <ng-container *ngIf="institute.county!=null && institute.county!=''">, {{institute.county}}</ng-container> -->
                                {{selected_institute.state_abbreviation}} {{selected_institute.zipcode}}
                            </span>
                        </p>
                        <p><i class="fa fa-phone"></i>
                            <span *ngIf="selected_institute.phone_number">{{selected_institute.phone_number |
                                phone}}</span>
                            <span *ngIf="!selected_institute.phone_number">NA</span>
                        </p>
                    </div>
                    <div class="active_user_block">
                        Divisions<b>{{selected_institute.total_divisions}}</b></div>
                    <div class="active_user_block">Units<b>{{selected_institute.total_units}}</b>
                    </div>
                    <div class="active_user_block">Users<b>{{selected_institute.total_users}}</b>
                    </div>
                    <p>
                        <i class="fa fa-list-ul"></i>
                        <span
                            *ngIf="selected_institute.institute_types && selected_institute.institute_types.display_text">{{selected_institute.institute_types.display_text}}</span>
                        <!-- <span
                            *ngIf="!(selected_institute.institute_types && selected_institute.institute_types.display_text)">NA</span><span
                            class="no_border d-none d-xxl-block"><i class="fa fa-user"></i>
                            <span class="no_border"
                                *ngIf="selected_institute.institute_owners && selected_institute.institute_owners.display_text">{{selected_institute.institute_owners.display_text}}</span>
                            <span class="no_border"
                                *ngIf="!(selected_institute.institute_owners && selected_institute.institute_owners.display_text)">NA</span>
                        </span> -->
                    </p>
                    <p><i class="fa fa-user"></i>
                        <span
                            *ngIf="selected_institute.institute_owners && selected_institute.institute_owners.display_text">{{selected_institute.institute_owners.display_text}}</span>
                        <span
                            *ngIf="!(selected_institute.institute_owners && selected_institute.institute_owners.display_text)">NA</span>
                    </p>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

        <div class="footer_button text-end">
            <button *ngIf="globals.selected_institute==null" type="button" class="all_btn theme_btn"
                (click)="selectOrg()" [disabled]="btnDisabled">Select</button>
            <button *ngIf="globals.selected_institute!=null" type="button" class="all_btn theme_btn"
                (click)="selectOrg()" [disabled]="btnDisabled">Switch</button>
        </div>

    </div>
    <!-- <kendo-dialog-actions>
                <button kendoButton (click)="close('no')">No</button>
                <button kendoButton (click)="close('yes')" primary="true">Yes</button>
            </kendo-dialog-actions> -->
</kendo-dialog>
<div class="clearfix"></div>