import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SubdomainPreferencesService {

  constructor(private http: HttpClient, private globals: Globals) { }

  getAllSubdomains() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/preference/get-all/subdomain')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getPreferedSubdomains(element) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/preference', element)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  updatePreferedSubdomain(element) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/preference/add', element)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
