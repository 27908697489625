<div class="inner_content_block landing_dashboard" [ngClass]="{'hasbreadcrumb_block': globals.institute_details && globals.authData.list.length>1}">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start" *ngIf="!individual_present">Your Dashboards</h1>
                    <h1 class="float-start" *ngIf="individual_present">Your Dashboards</h1>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <!-- <div class="grey_form_block" *ngIf="institute_count!=globals.authData.list.length">
                <form class="k-form" [formGroup]="selectForm">
                    <h5 class="text-center mb-5">Please select a Dashboard</h5>
                    <div class="clearfix"></div>
                    <div class="role_radio_block mb-4">
                        <div class="row justify-content-center">

                            <div class="col-12 col-xl-2 col-lg-4 col-md-6 col-sm-12" *ngIf="oess_present">
                                <div class="role_radio">
                                    <input formControlName="role" type="radio" id="oess" value="1" name="role" (click)="role=1"> 
                                    <label for="oess"><i class="fa fa-user"></i>
                                        OESS Role
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-xl-2 col-lg-4 col-md-6 col-sm-12" *ngIf="amsn_present">
                                <div class="role_radio">
                                    <input formControlName="role" type="radio" id="amsn" value="2" name="role" (click)="role=2"> 
                                    <label for="amsn"><i class="fa fa-user"></i>
                                        AMSN Role
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-xl-2 col-lg-4 col-md-6 col-sm-12" *ngIf="institute_count>0">
                                <div class="role_radio">
                                    <input formControlName="role" type="radio" id="Institute" value="3" name="role" (click)="role=3">
                                    <label for="Institute"><i class="fa fa-building"></i>
                                        Organization
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-xl-2 col-lg-4 col-md-6 col-sm-12" *ngIf="individual_present">
                                <div class="role_radio">
                                    <input formControlName="role" type="radio" id="Individual" value="5" name="role" (click)="role=5"> 
                                    <label for="Individual"><i class="fa fa-user"></i>
                                        Individual
                                    </label>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    </form>
                </div> -->
                <div class="clearfix"></div> 
                <div class="institute_dashbaord" *ngIf="role==3 && institute_count>1">
                    <div class="row">
                        <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="box_search_block">
                                <p class="padding_15">You are either administrating or associated with more than one organization. To administer or view the dashboard, please select an organization.</p>
                                <div class="clearfix"></div>
                                <div class="filter_block">
                                    <ul>
                                        <li><span class="id_box mb-0"></span>ID</li>
                                        <li><i class="fa fa-map-marker"></i>Address</li>
                                        <li><i class="fa fa-phone"></i>Phone Number</li>
                                        <li><i class="fa fa-list-ul"></i>Type</li>
                                        <li><i class="fa fa-user"></i>Ownership</li>
                                    </ul>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>

                        <ng-container *ngFor="let insti of instituteList; let i=index;">
                            <!-- <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex border_line"
                                *ngIf="insti.institute==''">
                                <label class="institute_block pb-0" for="institute{{i}}">
                                    <input type="radio" id="institute{{i}}" name="role_select" value="{{i}}"
                                        kendoRadioButton />
                                    <span class="check_block"></span>
                                    <div class="content_block">
                                        <h5>Individual</h5>
                                        <div class="id_box">{{globals.authData.amsn_user_id}}</div>
                                        <div class="clearfix"></div>
                                    </div>
                                </label>
                            </div> -->
                            <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex border_line"
                                *ngIf="insti.institute!='' && insti.institute.is_active==1">
                                <label class="institute_block" for="institute{{i}}">
                                    <input type="radio" id="institute{{i}}" name="role_select" value="{{i}}"
                                        kendoRadioButton />
                                    <span class="check_block"></span>
                                    <div class="content_block">
                                        <h5>{{insti.institute.institute_name}}</h5>
                                        <div *ngIf="insti.institute.provider_id" class="id_box">{{insti.institute.provider_id}}</div>
                                        <p><i class="fa fa-map-marker"></i>
                                            <span>{{insti.institute.address1}}<br>{{insti.institute.city}},
                                                <!-- <ng-container *ngIf="insti.institute.county!=null && insti.institute.county!=''">,
                                                    {{insti.institute.county}}</ng-container> -->
                                                {{insti.institute.states.state_abbreviation}} {{insti.institute.zipcode}}</span></p>
                                        <p><i class="fa fa-phone"></i>
                                            <span>{{insti.institute.phone_number | phone }}</span></p>
                                        <p *ngIf="insti.institute.institute_types && insti.institute.institute_types.display_text"><i class="fa fa-list-ul"></i>
                                            <span>{{insti.institute.institute_types.display_text}}</span></p>
                                        <p *ngIf="insti.institute.institute_owners && insti.institute.institute_owners.display_text"><i class="fa fa-user"></i>
                                            <span>{{insti.institute.institute_owners.display_text}}
                                            </span></p>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="button_block text-center">
                                        Your Role: <span>{{insti.role_name}}
                                        </span></div>
                                </label>
                            </div>
                        </ng-container>

                        <!-- <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 d-flex border_line">
                            <label class="institute_block pb-0" for="individual">
                                <input type="radio" id="individual" name="institute" kendoRadioButton />
                                <span class="check_block"></span>
                                <div class="content_block">
                                    <h5>Individual</h5>
                                    <div class="id_box">USR ID</div>
                                    <div class="clearfix"></div>
                                </div>
                            </label>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 d-flex border_line">
                            <label class="institute_block pb-0" for="institute1">
                                <input type="radio" id="institute1" name="institute" kendoRadioButton />
                                <span class="check_block"></span>
                                <div class="content_block">
                                    <h5>Institute Name</h5>
                                    <div class="id_box">Ins ID</div>
                                    <p><i class="fa fa-map-marker"></i>
                                        <span>Address 1 <br>City, County, State, Zip Code</span></p>
                                    <p><i class="fa fa-phone"></i> <span>(111) 111-1111</span></p>
                                    <p><i class="fa fa-list-ul"></i>
                                        <span>Type1, Type2, Type3</span></p>
                                    <p><i class="fa fa-user"></i> <span>Ownership
                                        </span></p>
                                    <div class="clearfix"></div>
                                </div>
                            </label>
                        </div> -->

                    </div>
                    <!-- <div class="text-center">
                        <button kendoButton (click)="changeRole()" [primary]="true"
                            class="all_btn theme_btn">Visit</button>
                        <button kendoButton (click)="clearRole()" class="all_btn themeoption_btn">Clear</button>
                    </div> -->
                </div>
                <!-- <div class="text-center">
                    <button kendoButton (click)="changeRole()" [primary]="true"
                        class="all_btn theme_btn">View Dashboard</button>
                    <button kendoButton (click)="clearRole()" class="all_btn themeoption_btn">Clear Selection</button>
                </div> -->
            </div>
        </div>
    </div>
</div>