import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { SubdomainPreferencesService } from '../services/subdomain-preferences.service';

import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
declare var Swal: any, _: any;

@Component({
  selector: 'app-subdomain-prefrences',
  templateUrl: './subdomain-prefrences.component.html',
  styleUrls: ['./subdomain-prefrences.component.css']
})
export class SubdomainPrefrencesComponent implements OnInit {

  subdomainList = [];
  domainWiseSubdomains = [];
  user_id: any = this.globals.authData.id;;

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private SubdomainPreferencesService: SubdomainPreferencesService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.getAllSubdomains();
  }

  getAllSubdomains() {
    if (this.user_id) {
      this.globals.isLoading = true;
      this.SubdomainPreferencesService.getAllSubdomains()
        .then((data) => {
          this.subdomainList = data['data'];
          this.getPreferedSubdomains();
        },
          (error) => {
            this.globals.isLoading = false;
            this.globals.errorSweetAlert();
          });
    } else {
      this.subdomainList = [];
    }
  }



  getPreferedSubdomains() {
    this.globals.isLoading = true;
    let entity = { 'user_id': this.user_id };
    this.SubdomainPreferencesService.getPreferedSubdomains(entity)
      .then((data) => {
        this.subdomainList.forEach(element => {
          element.preferred_subdomain = data['data'].includes(element['subdomain_id']) ? true : false;
        });
        this.domainWiseSubdomains = this.groupByDomain(this.subdomainList);
        for (var domain in this.domainWiseSubdomains) {
          this.domainWiseSubdomains[domain] = this.groupArr(this.domainWiseSubdomains[domain], 4);
        }
        this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
  }


  update() {
    this.globals.isLoading = true;
    var subdomain_ids = [];
    for (var domain in this.domainWiseSubdomains) {
      this.domainWiseSubdomains[domain].forEach(subdomain_group => {
        subdomain_ids = [...subdomain_ids, ...subdomain_group.filter(function (subdomain) {
          return subdomain.preferred_subdomain;
        }).map(value => value.subdomain_id)]
      });
    }
    let entity = { 'user_id': this.user_id, 'sub_domain_ids': subdomain_ids };
    this.SubdomainPreferencesService.updatePreferedSubdomain(entity)
      .then((data) => {
        this.globals.sweetAlert('success', 'Subdomain Preferences Update', 'Subdomain preferences has been updated successfully.');
        this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
  }

  toggle(dataItem: any, field: string): void {
    dataItem[field] = !dataItem[field];
  }

  groupByDomain(list) {
    return list.reduce(function (r, a) {
      r[a.domain_name] = r[a.domain_name] || [];
      r[a.domain_name].push(a);
      return r;
    }, Object.create(null));
  }

  groupArr(data, n) {
    var group = [];
    for (var i = 0, j = 0; i < data.length; i++) {
      if (i >= n && i % n === 0)
        j++;
      group[j] = group[j] || [];
      group[j].push(data[i])
    }
    return group;
  }


}
