import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Globals } from '../globals';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../app-assessment/services/user.service';
declare var $, Swal, PerfectScrollbar: any;

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .hide-icon.k-dialog-wrapper .k-dialog-titlebar .k-dialog-actions {
        display: none;
      }
    `,
  ],
})
export class BreadcrumbsComponent implements OnInit {
  institute_id =
    this.globals.selected_institute != null
      ? this.globals.selected_institute.id
      : null;
  selected_institute =
    this.globals.selected_institute != null
      ? this.globals.selected_institute
      : null;
  instituteList = [];
  Recently_instituteList = [];
  instituteListFilterData = [];
  orgError: boolean = false;
  OESS_Admin_present: boolean = false;
  AMSN_Admin_present: boolean = false;
  Individual_present: boolean = false;
  btnDisabled = false;

  constructor(
    public globals: Globals,
    private CookieService: CookieService,
    private UserService: UserService
  ) {}

  ngOnInit(): void {
    debugger;
    setTimeout(function () {
      new PerfectScrollbar('.switch_view_block');
    }, 500);

    let instituteList = [];
    let instituteList_rec = [];

    this.globals.authData.list.forEach(function (s) {
      if (s.institute != null && s.institute != '') {
        instituteList.push(s);
        instituteList_rec.push(s.institute.id);
      }
    });

    this.OESS_Admin_present =
      this.globals.authData.list.filter((s) => s.role_value == 1).length > 0;
    this.AMSN_Admin_present =
      this.globals.authData.list.filter((s) => s.role_value == 2).length > 0;
    this.Individual_present =
      this.globals.authData.list.filter((s) => s.role_value == 5).length > 0;

    let instituteList1 = [];
    instituteList.forEach(function (obj) {
      let data = {
        id: obj.institute.id,
        institute_name: obj.institute.institute_name,
      };
      instituteList1.push(data);
    });
    instituteList1.unshift({
      id: null,
      institute_name: 'Select Organization',
      is_active: 1,
      provider_id: '',
    });
    this.instituteList = instituteList1;
    this.instituteListFilterData = instituteList1;

    let Recently_instituteList_ids = [];
    let Recently_institutes = this.globals.Recently_institutes;
    Recently_institutes.forEach(function (s) {
      let index = $.inArray(s, instituteList_rec);
      if (index !== -1) {
        Recently_instituteList_ids.push(s);
        instituteList_rec.splice(index, 1);
      }
    });
    let end = 3 - Recently_instituteList_ids.length;
    if (Recently_instituteList_ids.length < 3) {
      for (let i = 0; i < end; i++) {
        if (instituteList_rec.length > i) {
          Recently_instituteList_ids.push(instituteList_rec[i]);
        }
      }
    }
    if (Recently_instituteList_ids.length > 0) {
      for (let i = 0; i < Recently_instituteList_ids.length; i++) {
        this.getInstituteDetailsById(Recently_instituteList_ids[i], i);
      }
    }

    if (
      this.globals.institute_details.role_value != 1 &&
      this.globals.institute_details.role_value != 2 &&
      this.globals.institute_details.role_value != 5
    ) {
      this.UserService.getInstituteDetailsById(
        this.globals.institute_details.institute.id
      ).then(
        (data: any) => {
          this.selected_institute = data['data'][0];
          this.globals.selected_institute = this.selected_institute;
          this.CookieService.set(
            'selected_institute',
            window.btoa(JSON.stringify(this.selected_institute)),
            365,
            '/',
            this.globals.CookieDomainUrl
          );
          this.globals.saveRecentlyInstitutes(this.selected_institute.id);
          this.btnDisabled = false;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        }
      );
    }
  }

  getInstituteDetailsById(institute_id, index) {
    this.UserService.getInstituteDetailsById(institute_id).then(
      (data: any) => {
        if (data['data'][0]) {
          this.Recently_instituteList[index] = data['data'][0];
        }
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  instituteFilter(value) {
    this.instituteList = this.instituteListFilterData.filter(
      (s) => s.institute_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  close() {
    this.globals.dialogOpened = false;
  }

  selectOrg() {
    if (this.institute_id != null) {
      this.orgError = false;
      this.CookieService.set(
        'selected_institute',
        window.btoa(JSON.stringify(this.selected_institute)),
        365,
        '/',
        this.globals.CookieDomainUrl
      );
      this.globals.saveRecentlyInstitutes(this.selected_institute.id);

      let institute_details = this.globals.authData.list.filter(
        (s) => s.institute && s.institute.id == this.institute_id
      )[0];
      this.CookieService.set(
        'institute_details',
        window.btoa(JSON.stringify(institute_details)),
        365,
        '/',
        this.globals.CookieDomainUrl
      );
      // window.location.reload();
      window.location.href = this.globals.baseUrl;
    } else {
      this.orgError = true;
    }
  }

  switchOrg(selected_institute) {
    this.CookieService.set(
      'selected_institute',
      window.btoa(JSON.stringify(selected_institute)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    this.globals.saveRecentlyInstitutes(selected_institute.id);

    let institute_details = this.globals.authData.list.filter(
      (s) => s.institute && s.institute.id == selected_institute.id
    )[0];
    this.CookieService.set(
      'institute_details',
      window.btoa(JSON.stringify(institute_details)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    // window.location.reload();
    window.location.href = this.globals.baseUrl;
  }

  switchRole(role_value) {
    let institute_details = this.globals.authData.list.filter(
      (s) => s.role_value == role_value
    )[0];
    this.CookieService.set(
      'institute_details',
      window.btoa(JSON.stringify(institute_details)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    // window.location.reload();
    window.location.href = this.globals.baseUrl;
  }

  filterData(id) {
    this.institute_id = id.id;
    this.orgError = false;
    if (this.institute_id != null) {
      this.btnDisabled = true;
      this.UserService.getInstituteDetailsById(this.institute_id).then(
        (data: any) => {
          this.selected_institute = data['data'][0];
          this.btnDisabled = false;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        }
      );
    } else {
      this.selected_institute = null;
      this.orgError = true;
    }
  }

  select_view() {
    $('.switch_view_block').addClass('active_view');
  }

  select_view_close() {
    $('.switch_view_block').removeClass('active_view');
  }
}
