<div class="covid_frontend_block">
  <div class="loader_block" *ngIf="globals.isLoading" >
    <div class="height_loader">
      <div class="img_loader">
        <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
        <div class="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
  <app-header *ngIf="globals.authData!=null"></app-header>
  <app-side-menu *ngIf="globals.authData!=undefined"></app-side-menu>
  <app-breadcrumbs *ngIf="globals.authData!=undefined && globals.institute_details!=null"></app-breadcrumbs>
  <router-outlet></router-outlet>
  <app-footer *ngIf="globals.authData!=null"></app-footer>
</div>