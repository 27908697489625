import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Globals } from './globals';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorClassService } from './http-interceptor-class.service';

// Components
import { LoginComponent } from './login/login.component';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { PermissionNotFoundComponent } from './permission-not-found/permission-not-found.component';
import { IntermediatePageComponent } from './intermediate-page/intermediate-page.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SubdomainPrefrencesComponent } from './subdomain-prefrences/subdomain-prefrences.component';
import { AuthenticationFailedComponent } from './authentication-failed/authentication-failed.component';

// Services
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { RegistrationService } from './services/registration.service';
import { PasswordService } from './services/password.service';
import { ProfileService } from './services/profile.service';
import { DashboardService } from './services/dashboard.service';
import { SubdomainPreferencesService } from './services/subdomain-preferences.service';



const routes: Routes = [
  { path: '', redirectTo: 'assessment/my-assessments', pathMatch: 'full' },

  // { path: 'authentication/:token', component: IntermediatePageComponent, canActivate: [AuthGuard], data: { title: 'Authentication' } },
  { path: 'authentication', component: IntermediatePageComponent, canActivate: [AuthGuard],data: { title: 'Authentication' } },
  { path: 'landing-dashboard', component: LandingDashboardComponent, canActivate: [AuthGuard], data: { title: 'Landing Dashboard' } },
  //{ path: 'admin/login', component: LoginComponent, canActivate: [AuthGuard] },

  { path: 'permission-not-found', component: PermissionNotFoundComponent, data: { title: 'Permission Not Found' } },
  { path: 'page-not-found', component: NotFoundComponent, data: { title: 'Page Not Found' } },
  { path: 'authentication-failed', component: AuthenticationFailedComponent, data: { title: 'Authentication Failed' } },

  { path: 'subdomain-preference', component: SubdomainPrefrencesComponent, canActivate: [AuthGuard], data: { title: 'Subdomain Preferences' } },
  // { path: 'assessment', loadChildren: () => import('./app-assessment/app-assessment.module').then(m => m.AppAssessmentModule) },
  { path: 'assessment', loadChildren: () => import('./app-assessment_covid/app-assessment.module').then(m => m.AppAssessmentModule) },
  // { path: '**', redirectTo: 'assessment/my-assessments' },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [Globals, AuthService, AuthGuard, RegistrationService, PasswordService, ProfileService, DashboardService, SubdomainPreferencesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorClassService,
      multi: true
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
